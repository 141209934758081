import { useState } from 'react';
import { useNavigate } from 'react-router';
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar } from '@mui/material';
import MenuPopover from '../../../components/MenuPopover';
import { IconButtonAnimate } from '../../../components/animate';
import SettingMode from 'src/components/settings/drawer/SettingMode';
import SettingLayout from 'src/components/settings/drawer/SettingLayout';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import useLocales from 'src/hooks/useLocales';
import { OptionsObject, useSnackbar } from 'notistack';

export default function AccountPopover() {
  const navigate = useNavigate();

  const { currentLang, translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  const { enqueueSnackbar } = useSnackbar();

  const { user, logout } = useAuth();

  const [open, setOpen] = useState<HTMLElement | null>(null);

  const MENU_OPTIONS = [
    {
      label: `${translate('accountPopover.profile')}`,
      linkTo: PATH_DASHBOARD.account.profile,
    },
  ];

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleNavigate = (link: string) => {
    navigate(link);

    handleClose();
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate(PATH_AUTH.login, { replace: true });

      if (isMountedRef.current) {
        // TODO to understand how it works
        handleClose();
      }
    } catch (error) {
      console.error(error);

      const snackbarProps: OptionsObject = {
        variant: 'error',
      };

      enqueueSnackbar(translate('accountPopover.errorMsg.logoutFailed'), snackbarProps);
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar // FIXME check in the Minimal template
          src="{user?.photoURL}"
          alt={
            currentLang.value === 'hu'
              ? `${user?.lastName} ${user?.firstName}`
              : `${user?.firstName} ${user?.lastName}`
          }
        />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {currentLang.value === 'hu'
              ? `${user?.lastName} ${user?.firstName}`
              : `${user?.firstName} ${user?.lastName}`}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <SettingMode />
        <SettingLayout />

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleNavigate(option.linkTo)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {translate('accountPopover.logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
