import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { ReactNode, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { handleRefreshToken } from 'src/utils/jwt';

type Props = {
  children: ReactNode;
};

let interval = 0;
const promptBeforeIdle = 60 * 1000;

export const AutoLogoutProvider = ({ children }: Props) => {
  const { translate } = useLocales();
  const { isAuthenticated, logout } = useAuth();

  const [openDialog, setOpenDialog] = useState(false);
  const [idleTimeout, setIdleTimeout] = useState<number>();
  const [remainingTime, setRemainingTime] = useState(Infinity);

  const handleLogout = async () => {
    setOpenDialog(false);
    window.clearInterval(interval);

    await logout();
  };

  const handleExtensionOfLoggedInStatus = async () => {
    const refreshToken = localStorage.getItem('refreshToken');

    if (refreshToken) {
      await handleRefreshToken({ refreshToken, needsRefreshToken: true });

      const newRefreshToken = localStorage.getItem('refreshToken');

      if (newRefreshToken) {
        const idleTimeout = getIdleTimeout(newRefreshToken);

        setIdleTimeout(idleTimeout);
        setOpenDialog(false);
        reset();
      } else {
        handleLogout();
      }
    } else {
      handleLogout();
    }
  };

  const onIdle = () => {
    handleLogout();
  };

  const onPrompt = () => {
    if (isAuthenticated) {
      const remainingTimeInSeconds = Math.ceil(getRemainingTime() / 1000);

      setRemainingTime(remainingTimeInSeconds);
      setOpenDialog(true);
    }
  };

  const onAction = () => {
    const refreshToken = localStorage.getItem('refreshToken');
    const rememberMe = localStorage.getItem('rememberMe');

    if (refreshToken && rememberMe === 'true' && getRemainingTime() > promptBeforeIdle) {
      const idleTimeout = getIdleTimeout(refreshToken);

      setIdleTimeout(idleTimeout);
    }
  };

  const { start, reset, getRemainingTime } = useIdleTimer({
    onIdle,
    onPrompt,
    onAction,
    crossTab: true,
    stopOnIdle: true,
    timeout: idleTimeout,
    promptBeforeIdle,
    syncTimers: 200,
    throttle: 500,
    eventsThrottle: 2000,
  });

  useEffect(() => {
    if (isAuthenticated) {
      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken) {
        const idleTimeout = getIdleTimeout(refreshToken);
        const autoRefreshTime = Math.ceil(idleTimeout / 1000) - 30;

        setIdleTimeout(idleTimeout);

        start();

        interval = window.setInterval(async () => {
          const newRefreshToken = localStorage.getItem('refreshToken');
          const remainingTimeInSeconds = Math.ceil(getRemainingTime() / 1000);

          if (remainingTimeInSeconds === autoRefreshTime) {
            await handleRefreshToken({ refreshToken: newRefreshToken ?? refreshToken });
          }

          if (getRemainingTime() <= promptBeforeIdle) {
            setRemainingTime(remainingTimeInSeconds);
          }
        }, 1000);
      }
    }

    return () => window.clearInterval(interval);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <Dialog open={openDialog}>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" marginBottom={1}>
            <Typography variant="inherit"> {translate('dialog.autoLogout.title')}</Typography>
            <Typography variant="inherit">{getFormattedTime(remainingTime)}</Typography>
          </Box>
        </DialogTitle>
        <DialogContent data-testid="dialog-content">
          {translate('dialog.autoLogout.content')}
        </DialogContent>
        <DialogActions sx={{ padding: '10px' }}>
          <Stack
            direction={{ xs: 'column-reverse', sm: 'row' }}
            spacing={1}
            width={{ xs: '100%', sm: 'inherit' }}
          >
            <Button
              onClick={handleLogout}
              variant="outlined"
              color="error"
              disableFocusRipple
              data-testid="confirm-dialog"
            >
              {translate('dialog.autoLogout.logout')}
            </Button>
            <Button
              onClick={handleExtensionOfLoggedInStatus}
              variant="contained"
              autoFocus
              disableFocusRipple
              data-testid="confirm-dialog"
            >
              {translate('dialog.autoLogout.stayLoggedIn')}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      {children}
    </>
  );
};

const getFormattedTime = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const formattedMinutes = minutes > 9 ? minutes : '0' + minutes;

  const seconds = timeInSeconds % 60;
  const formattedSeconds = seconds > 9 ? seconds : '0' + seconds;

  return `${formattedMinutes}:${formattedSeconds}`;
};

const getIdleTimeout = (refreshToken: string) => {
  const { exp } = jwtDecode<{ exp: number }>(refreshToken);
  const idleTimeout = exp * 1000 - Date.now();

  return idleTimeout;
};
