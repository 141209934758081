import React, { useState } from 'react';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormProvider, RHFSelect, RHFTextField } from 'src/components/hook-form';
import useLocales from 'src/hooks/useLocales';
import { OfflineTicketType, OfflineTicketTypeRequestBody } from 'src/@types/OfflineTickets';
import axios from 'src/utils/axios';
import { useAppSelector } from 'src/redux/hook';
import { OptionsObject, enqueueSnackbar } from 'notistack';
import { EventData } from 'src/@types/event';
import { TicketType } from 'src/@types/ticket';
import { NumberFormatCustom } from 'src/components/number-format/NumberFormatCustom';
import { Currency } from 'src/@types/user';
import MultilingualName from 'src/components/MultilingualName';
import { Box } from '@mui/system';
import { handleNumberTransformation } from 'src/utils/yup-number-transformer';

interface NewOfflineTicketFormProps {
  handleClose: () => void;
  event: EventData;
  onlineTickets: TicketType[];
  onAddNewOfflineTicketType: (newOfflineTicketType: OfflineTicketType) => void;
  onUpdateOfflineTicketTypes: (updatedOfflineTicketType: OfflineTicketType) => void;
  isEditMode?: boolean;
  editedOfflineTickets?: OfflineTicketType;
}

type FormValuesProps = {
  selectTicketTypeName?: string;
  ticketTypeName: string;
  ticketPrice: number;
  limit?: number | null;
};

const NewOfflineTicketForm: React.FC<NewOfflineTicketFormProps> = ({
  handleClose,
  event,
  onlineTickets,
  onAddNewOfflineTicketType,
  onUpdateOfflineTicketTypes,
  isEditMode,
  editedOfflineTickets,
}) => {
  const { translate, currentLang } = useLocales();
  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);
  const currentRole = useAppSelector((state) => state.userRoles.currentUserRole);

  const [openLimitAlert, setOpenLimitAlert] = useState(false);

  const organizerCurrency = currentOrganizer?.currency
    ? (Currency[currentOrganizer.currency as keyof typeof Currency] ?? currentOrganizer.currency)
    : 'Ft';

  const ntakDataValidationSchema = Yup.object().shape({
    ticketTypeName: Yup.string().required(
      `${translate('errorMsg.required', {
        fieldNameHu: 'Jegytípus neve',
        fieldNameEn: 'ticketTypeName',
      })}`
    ),
    ticketPrice: Yup.number()
      .transform((value, originalValue) => handleNumberTransformation({ value, originalValue }))
      .required()
      .moreThan(0, `${translate('ntak.boxoffice.offlineTicket.errorMsg.greaterThanZero')}`),
    selectTicketTypeName: Yup.string(),
    limit: Yup.number()
      .nullable()
      .transform((value, originalValue) => handleNumberTransformation({ value, originalValue })),
  });

  const defaultValues: FormValuesProps = {
    selectTicketTypeName: '',
    ticketTypeName: editedOfflineTickets?.ticketTypeName || '',
    ticketPrice: editedOfflineTickets?.ticketPrice || 0,
    limit: editedOfflineTickets?.limit,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ntakDataValidationSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitting, isValid, errors },
  } = methods;

  const onSubmit = async () => {
    try {
      let data: FormValuesProps = getValues();

      const formData = {
        ticketTypeName: data.ticketTypeName,
        ticketPrice: Number(data.ticketPrice),
        limit: data.limit ? Number(data.limit) : undefined,
      };

      if (isEditMode) {
        await patchOfflineTicketType(formData);
      } else {
        await postOfflineTicketType(formData);
      }
    } catch (error) {
      console.error('Error submitting the report:', error);
    }
  };

  const postOfflineTicketType = async (data: OfflineTicketTypeRequestBody) => {
    try {
      const { data: newOfflineTicketType } = await axios.post(
        `organizer/${currentOrganizer?.id}/event/${event?.id}/offline-ticket-type`,
        data,
        {
          headers: {
            'organizer-id': currentOrganizer ? currentOrganizer.id.toString() : '',
            'role-id': currentRole ? currentRole.id.toString() : '',
          },
        }
      );

      const snackbarProps: OptionsObject = {
        variant: 'success',
        autoHideDuration: 1500,
      };

      onAddNewOfflineTicketType(newOfflineTicketType);

      enqueueSnackbar(translate('ntak.attractionRegistration.data.saveSuccess'), snackbarProps);
      handleClose();
    } catch (error) {
      console.error('Error submitting the report:', error);

      const snackbarProps: OptionsObject = {
        variant: 'error',
      };

      enqueueSnackbar(translate('ntak.attractionRegistration.data.saveFailed'), snackbarProps);
    }
  };

  const patchOfflineTicketType = async (data: OfflineTicketTypeRequestBody) => {
    try {
      const { data: updatedOfflineTicketType } = await axios.patch(
        `organizer/${currentOrganizer?.id}/event/${event?.id}/offline-ticket-type/${editedOfflineTickets?.id}`,
        data,
        {
          headers: {
            'organizer-id': currentOrganizer ? currentOrganizer.id.toString() : '',
            'role-id': currentRole ? currentRole.id.toString() : '',
          },
        }
      );

      const snackbarProps: OptionsObject = {
        variant: 'success',
        autoHideDuration: 1500,
      };

      onUpdateOfflineTicketTypes(updatedOfflineTicketType);

      enqueueSnackbar(translate('ntak.attractionRegistration.data.saveSuccess'), snackbarProps);
      handleClose();
    } catch (error) {
      console.error('Error submitting the report:', error);

      const snackbarProps: OptionsObject = {
        variant: 'error',
      };

      enqueueSnackbar(translate('ntak.attractionRegistration.data.saveFailed'), snackbarProps);
    }
  };

  const handleCloseLimitAlertDialog = () => {
    setOpenLimitAlert(false);
  };

  const handleFormSubmit = () => {
    const { limit } = getValues();
    if (!limit) {
      setOpenLimitAlert(true);
    } else {
      onSubmit();
    }
  };

  return (
    <FormProvider testId="offlineTicket-submit-form" methods={methods} onSubmit={handleFormSubmit}>
      <Dialog open={openLimitAlert} onClose={handleCloseLimitAlertDialog}>
        <DialogTitle>
          {translate('ntak.boxoffice.offlineTicket.confirmationDialog.title')}
        </DialogTitle>
        <DialogContent>
          {translate('ntak.boxoffice.offlineTicket.confirmationDialog.content')}
        </DialogContent>
        <DialogActions sx={{ padding: '10px' }}>
          <Button onClick={handleCloseLimitAlertDialog} variant="outlined" autoFocus>
            {translate('ntak.boxoffice.offlineTicket.confirmationDialog.back')}
          </Button>
          <Button
            loading={isSubmitting}
            color="error"
            onClick={handleSubmit(onSubmit)}
            variant="contained"
            autoFocus
          >
            <Typography variant="inherit">
              {translate('ntak.boxoffice.offlineTicket.confirmationDialog.confirm')}
            </Typography>
          </Button>
        </DialogActions>
      </Dialog>

      <Stack spacing={1.5}>
        <RHFSelect
          name="selectTicketTypeName"
          label={translate('ntak.boxoffice.offlineTicket.existingTicketType')}
          placeholder="ticketTypeName"
          size="small"
          slotProps={{
            htmlInput: {
              'data-testid': 'ticketTypeName',
            },
          }}
          onChange={(event) => {
            const selectedValue = event.target.value;
            setValue('ticketTypeName', selectedValue);
            setValue('selectTicketTypeName', selectedValue);
          }}
        >
          {onlineTickets?.map((ticket) => (
            <MenuItem
              key={ticket.id}
              value={currentLang.value === 'hu' ? ticket.name : ticket.nameEnglish || ticket.name}
            >
              <MultilingualName name={ticket.name} englishName={ticket.nameEnglish} />
            </MenuItem>
          ))}
        </RHFSelect>
        <Box>
          <RHFTextField
            type="string"
            name="ticketTypeName"
            label={translate('ntak.boxoffice.offlineTicket.ticketType')}
            variant="outlined"
            fullWidth
            size="small"
            required
          />
          {!errors.ticketTypeName && (
            <FormHelperText sx={{ mt: 0, pl: 1, wordBreak: 'break-word' }}>
              <Typography variant="caption" color="inherit">
                {translate('ntak.boxoffice.offlineTicket.actions.ticketTypeNameHelper')}
              </Typography>
            </FormHelperText>
          )}
          {errors.ticketTypeName && typeof errors.ticketTypeName.message === 'string' && (
            <FormHelperText sx={{ mt: 0, pl: 1, wordBreak: 'break-word' }}>
              <Typography variant="caption" color="error">
                {errors.ticketTypeName.message}
              </Typography>
            </FormHelperText>
          )}
        </Box>
        <RHFTextField
          name="ticketPrice"
          label={translate('ntak.boxoffice.offlineTicket.price')}
          variant="outlined"
          fullWidth
          size="small"
          required
          margin="normal"
          slotProps={{
            input: {
              endAdornment: <InputAdornment position="end">{organizerCurrency}</InputAdornment>,
              inputComponent: NumberFormatCustom as any,
            },
          }}
        />
        {errors.ticketPrice && typeof errors.ticketPrice.message === 'string' && (
          <Typography variant="caption" color="error">
            {errors.ticketPrice.message}
          </Typography>
        )}
        <RHFTextField
          name="limit"
          label={translate('ntak.boxoffice.offlineTicket.quantity')}
          variant="outlined"
          fullWidth
          size="small"
          margin="normal"
          slotProps={{
            input: {
              inputComponent: NumberFormatCustom as any,
            },
          }}
        />
        {errors.limit && typeof errors.limit.message === 'string' && (
          <Typography variant="caption" color="error">
            {errors.limit.message}
          </Typography>
        )}
      </Stack>
      <Stack justifyContent="flex-end" direction="row" marginTop={6} spacing={2}>
        <Button variant="outlined" onClick={handleClose}>
          {translate('eventNewEdit.ticket.offer.form.cancel')}
        </Button>
        <Button
          type="submit"
          variant="contained"
          loading={isSubmitting}
          data-testid="report-submit-form"
          disabled={!isValid}
        >
          <Typography variant="inherit">
            {translate('ntak.attractionRegistration.data.save')}
          </Typography>
        </Button>
      </Stack>
    </FormProvider>
  );
};

export default NewOfflineTicketForm;
