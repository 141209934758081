import { TableRow, TableCell, BoxProps } from '@mui/material';
import useLocales from 'src/hooks/useLocales';
import TableEmptyContent from './TableEmptyContent';

type Props = BoxProps & {
  isNotFound: boolean;
  title?: string;
  dense?: boolean;
  hasSummary?: boolean;
};

export default function TableNoData({
  isNotFound,
  title,
  dense = true,
  hasSummary,
  ...other
}: Props) {
  const { translate } = useLocales();

  return (
    <TableRow>
      {isNotFound ? (
        <TableCell colSpan={12}>
          <TableEmptyContent
            title={title ?? translate('table.content.noData')}
            dense={dense}
            hasSummary={hasSummary}
            {...other}
          />
        </TableCell>
      ) : (
        <TableCell colSpan={12} sx={{ p: 0 }} />
      )}
    </TableRow>
  );
}
