import 'simplebar-react/dist/simplebar.min.css';
import '@fontsource/nunito';
import 'react-quill/dist/quill.snow.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import { HelmetProvider } from 'react-helmet-async';
import { SettingsProvider } from './contexts/SettingsContext';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import App from './App';
import { AuthProvider } from './contexts/JWTContext';
import { Provider } from 'react-redux';
import store from './redux/index';
import SnackbarProvider from './components/snackbar/SnackbarProvider';
import { BakcdropProvider } from './contexts/BackdropContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <Provider store={store}>
    <AuthProvider>
      <HelmetProvider>
        <SettingsProvider>
          <BakcdropProvider>
            <CollapseDrawerProvider>
              <BrowserRouter>
                <SnackbarProvider>
                  <App />
                </SnackbarProvider>
              </BrowserRouter>
            </CollapseDrawerProvider>
          </BakcdropProvider>
        </SettingsProvider>
      </HelmetProvider>
    </AuthProvider>
  </Provider>
);
