import { useFormContext, Controller } from 'react-hook-form';
import { MuiTelInput, MuiTelInputProps } from 'mui-tel-input';
import { useState } from 'react';

type IProps = {
  name: string;
  size?: 'small' | 'medium';
  testId: string;
};

type Props = IProps & MuiTelInputProps;

const hungaryCountryCode = 'HU';

export default function RHFTextField({ name, label, size, testId, langOfCountryName }: Props) {
  const { control } = useFormContext();

  const [countryCode, setCountryCode] = useState<string | null>(hungaryCountryCode);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, ...fieldProps }, fieldState: { error } }) => (
        <MuiTelInput
          {...fieldProps}
          forceCallingCode
          size={size}
          langOfCountryName={langOfCountryName}
          fullWidth
          value={fieldProps.value}
          inputRef={ref}
          error={!!error}
          helperText={error?.message}
          variant="outlined"
          placeholder={countryCode === 'HU' ? '30 123 4567' : ''}
          defaultCountry={hungaryCountryCode}
          label={label}
          slotProps={{
            htmlInput: {
              'data-testid': testId,
            },
          }}
          onChange={(value, info) => {
            onChange(value);
            setCountryCode(info.countryCode);
          }}
        />
      )}
    />
  );
}
