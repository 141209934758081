import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { styled } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Typography,
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import useResponsive from '../../hooks/useResponsive';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useAuth from 'src/hooks/useAuth';
import useLocales from 'src/hooks/useLocales';
import { HEADER, NAVBAR } from '../../config';
import axios from 'src/utils/axios';
import NavbarVertical from './navbar/NavbarVertical';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import DashboardHeader from './header';
import OrganizerSelect from './header/OrganizerSelect';
import { userOrganizersActions } from 'src/redux/user-organizers';
import { userRolesActions } from 'src/redux/user-roles';
import { useAppDispatch, useAppSelector } from 'src/redux/hook';
import { useGetFeatureFlagsQuery, useGetOrganizersQuery } from 'src/redux/api/userAPI';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { getFilteredNavConfig } from './utils/filtered-nav-config';
import { RoleEnum } from 'src/@types/auth';

type MainStyleProps = {
  collapseClick: boolean;
};

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})<MainStyleProps>(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: HEADER.MOBILE_HEIGHT + 24,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

enum AlertType {
  DELETE_CURRENT_ORGANIZER,
  DELETE_ALL_ORGANIZER,
}

export default function DashboardLayout() {
  const navigate = useNavigate();

  const { collapseClick, isCollapse } = useCollapseDrawer();

  const { themeLayout } = useSettings();

  const { translate, currentLang } = useLocales();

  const isDesktop = useResponsive('up', 'lg');

  const smDown = useResponsive('down', 'sm');

  const { user } = useAuth();

  const reduxDispatch = useAppDispatch();

  const { setCurrentUserOrganizer } = userOrganizersActions;
  const { setCurrentUserRole, setPreviousRoles, setPermissionsByRoles } = userRolesActions;

  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);
  const currentRole = useAppSelector((state) => state.userRoles.currentUserRole);
  const permissionsByRoles = useAppSelector((state) => state.userRoles.permissionsByRoles);
  const previousRoles = useAppSelector((state) => state.userRoles.previousRoles);

  const [alertType, setAlertType] = useState<AlertType>();

  const { data: featureFlags } = useGetFeatureFlagsQuery(
    { organizerId: currentOrganizer?.id },
    { skip: !currentOrganizer }
  );

  const {
    data: organizers,
    isLoading: organizersLoading,
    error: organizersError,
  } = useGetOrganizersQuery(user?.id);

  const [openSidebar, setOpenSidebar] = useState(false);

  const [openDeletedOrganizerDialog, setOpenDeletedOrganizerDialog] = useState(false);
  const [deltedOrganizerDialogContent, setDeletedOrganizerDialogContent] = useState('');
  const [preventResendEmail, setPreventResendEmail] = useState(false);

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const verticalLayout = themeLayout === 'vertical';

  useEffect(() => {
    if (!currentOrganizer && !!organizers?.length) {
      const getUserRoles = async () => {
        const [organizer] = organizers;

        try {
          const { data: rolesByOrganizer } = await axios.get(
            `user/${user?.id}/organizer/${organizer.id}/role`
          );

          const [userRole] = rolesByOrganizer;

          reduxDispatch(setCurrentUserOrganizer(organizer));
          reduxDispatch(setCurrentUserRole(userRole));
          reduxDispatch(setPreviousRoles(rolesByOrganizer));

          localStorage.setItem('currentOrganizerId', organizer.id.toString());
          localStorage.setItem('currentAccountId', userRole.id.toString());
        } catch (error) {
          console.error(error);
        }
      };

      getUserRoles();
    }
  }, [
    currentOrganizer,
    organizers,
    reduxDispatch,
    setPreviousRoles,
    setCurrentUserRole,
    setCurrentUserOrganizer,
    user?.id,
  ]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (!organizersLoading && !organizersError && !!currentOrganizer) {
      switch (true) {
        case !organizers?.length:
          pathname !== PATH_DASHBOARD.home && navigate(PATH_DASHBOARD.home, { replace: true });

          setAlertType(AlertType.DELETE_ALL_ORGANIZER);

          setDeletedOrganizerDialogContent(
            translate('dialog.deletedOrganizer.deletedAllOrganizerContent')
          );
          setOpenDeletedOrganizerDialog(true);

          !!previousRoles.length && reduxDispatch(setPreviousRoles([]));

          break;
        case currentRole?.name !== RoleEnum.FREE_ORGANIZER &&
          !organizers?.find((organizer) => organizer.id === currentOrganizer?.id):
          pathname !== PATH_DASHBOARD.home && navigate(PATH_DASHBOARD.home, { replace: true });

          setAlertType(AlertType.DELETE_CURRENT_ORGANIZER);

          setDeletedOrganizerDialogContent(translate('dialog.deletedOrganizer.content'));
          setOpenDeletedOrganizerDialog(true);

          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentOrganizer,
    currentOrganizer?.id,
    organizers,
    organizersError,
    organizersLoading,
    previousRoles.length,
    reduxDispatch,
    setPreviousRoles,
    translate,
    navigate,
  ]);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseDeletedOrganizerDialog = () => {
    if (alertType === AlertType.DELETE_ALL_ORGANIZER) {
      reduxDispatch(setCurrentUserOrganizer(undefined));
      reduxDispatch(setCurrentUserRole(undefined));
      reduxDispatch(setPermissionsByRoles([]));

      localStorage.removeItem('currentOrganizerId');
      localStorage.removeItem('currentAccountId');
    }

    setOpenDeletedOrganizerDialog(false);
  };

  const handleResendVerificationEmail = async () => {
    try {
      if (!preventResendEmail) {
        await axios.post(
          'user/email/verification',
          {
            email: user?.email,
          },
          {
            headers: {
              'Accept-Language': currentLang.value,
            },
          }
        );

        setPreventResendEmail(true);

        setTimeout(() => setPreventResendEmail(false), 60000);
      }

      setDialogContent(translate('resendEmail.successMsg'));
      setOpenDialog(true);
    } catch (error) {
      console.error(error);

      setDialogContent(translate('resendEmail.errorMsg'));
      setOpenDialog(true);
    }
  };

  const filteredNavConfig = getFilteredNavConfig({
    currentRole,
    featureFlags,
    permissions: permissionsByRoles,
  });

  return (
    <>
      <Dialog open={openDialog} onClose={handleCloseDialog} data-testid="resend-email-dialog">
        <DialogTitle>{translate('resendEmail.dialogTitle')}</DialogTitle>
        <DialogContent data-testid="dialog-content">{dialogContent}</DialogContent>
        <DialogActions sx={{ padding: '10px' }}>
          <Button
            onClick={handleCloseDialog}
            variant="outlined"
            autoFocus
            data-testid="confirm-dialog"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeletedOrganizerDialog}>
        <DialogTitle>{translate('dialog.deletedOrganizer.title')}</DialogTitle>
        <DialogContent>
          <Typography marginBottom={3}>{deltedOrganizerDialogContent}</Typography>
          <Box marginBottom={3}>
            <OrganizerSelect
              isCurrentOrganizerDeleted={true}
              onClose={handleCloseDeletedOrganizerDialog}
            />
          </Box>
        </DialogContent>
        {alertType === AlertType.DELETE_ALL_ORGANIZER && (
          <DialogActions sx={{ padding: '10px' }}>
            <Button onClick={handleCloseDeletedOrganizerDialog} variant="outlined" autoFocus>
              OK
            </Button>
          </DialogActions>
        )}
      </Dialog>

      {verticalLayout ? (
        <>
          <DashboardHeader
            onOpenSidebar={() => setOpenSidebar(true)}
            verticalLayout={verticalLayout}
          />

          {isDesktop ? (
            <NavbarHorizontal navConfig={filteredNavConfig} />
          ) : (
            <NavbarVertical
              isOpenSidebar={openSidebar}
              navConfig={filteredNavConfig}
              onCloseSidebar={() => setOpenSidebar(false)}
            />
          )}

          <Box
            component="main"
            sx={{
              px: { lg: 2 },
              pt: {
                xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
              },
              pb: {
                xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
                lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
              },
            }}
          >
            {smDown && (
              <Box margin={2} paddingBottom={2} marginTop={-1}>
                <OrganizerSelect />
              </Box>
            )}

            {!user?.isEmailConfirmed && (
              <Alert
                severity="warning"
                sx={{ mx: smDown ? 2 : 3, mb: 3 }}
                data-testid="confirm-email-msg"
              >
                {translate('emailConfirmationMsg')}
                <Link
                  href="#"
                  onClick={handleResendVerificationEmail}
                  data-testid="resend-email-link"
                >
                  {translate('resendEmail.link')}
                </Link>
              </Alert>
            )}
            <Outlet />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          <DashboardHeader isCollapse={isCollapse} onOpenSidebar={() => setOpenSidebar(true)} />

          <NavbarVertical
            isOpenSidebar={openSidebar}
            navConfig={filteredNavConfig}
            onCloseSidebar={() => setOpenSidebar(false)}
          />
          <MainStyle collapseClick={collapseClick}>
            {smDown && (
              <Box margin={2} paddingBottom={2} marginTop={-1}>
                <OrganizerSelect />
              </Box>
            )}
            {!user?.isEmailConfirmed && (
              <Alert severity="warning" sx={{ mx: 3, mb: 3 }} data-testid="confirm-email-msg">
                {translate('emailConfirmationMsg')}
                <Link
                  href="#"
                  data-testid="resend-email-link"
                  onClick={handleResendVerificationEmail}
                >
                  {translate('resendEmail.link')}
                </Link>
              </Alert>
            )}
            <Outlet />
          </MainStyle>
        </Box>
      )}
    </>
  );
}
