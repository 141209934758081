import { Button, Container } from '@mui/material';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { OfflineTicketType } from 'src/@types/OfflineTickets';
import { PagePermissionEnum } from 'src/@types/auth';
import { EventData } from 'src/@types/event';
import { IconifyIconEnum } from 'src/@types/icon';
import { NotFoundType } from 'src/@types/types';
import CurrentRoleSettings from 'src/components/CurrentRoleSettings';
import CustomizedModal from 'src/components/CustomizedModal';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import NotFoundRecord from 'src/components/NotFoundRecord';
import Page from 'src/components/Page';
import IconifyIcon from 'src/components/icons/IconifyIcon';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
import { PATH_DASHBOARD } from 'src/routes/paths';
import NewOfflineTicketForm from 'src/sections/@dashboard/box-office/offline-tickets/NewOfflineTicketForm';
import OfflineTicketEditor from 'src/sections/@dashboard/box-office/offline-tickets/OfflineTicketEditor';

const NtakBoxOfficeOfflineTicketEditor = () => {
  const { translate } = useLocales();

  const location = useLocation();

  const isMobile = useResponsive('down', 'sm');

  const { event }: { event?: EventData } = location.state || {};

  const { id } = useParams();

  const [offlineTickets, setOfflineTickets] = useState<OfflineTicketType[]>(
    event?.offlineTicketTypes ?? []
  );

  const [isEditMode, setIsEditMode] = useState(false);

  const [editedOfflineTickets, setEditedOfflineTickets] = useState<OfflineTicketType | undefined>(
    undefined
  );
  const [openNewOfflineTicketModal, setOpenNewOfflineTicketModal] = useState(false);

  const handleOpenNewOfflineTicketModal = () => {
    setOpenNewOfflineTicketModal(true);
    setEditedOfflineTickets(undefined);
  };

  const handleAddNewOfflineTicketType = (newOfflineTicketType: OfflineTicketType) =>
    setOfflineTickets((prev) => [...prev, newOfflineTicketType]);

  const handleUpdateOfflineTicketType = (updateOfflineTicketType: OfflineTicketType) => {
    const updateOfflineTicketTypeId = updateOfflineTicketType.id;

    setOfflineTickets((prev) =>
      prev.map((offlineTicketType) =>
        offlineTicketType.id === updateOfflineTicketTypeId
          ? updateOfflineTicketType
          : offlineTicketType
      )
    );
  };

  const handleCloseNewOfflineTicketModal = () => {
    setOpenNewOfflineTicketModal(false);
    setIsEditMode(false);
  };

  if (!event) {
    return <NotFoundRecord id={id} type={NotFoundType.EVENT} />;
  }

  return (
    <Page title={translate('pageTitles.boxOfficeOfflineTickets')}>
      <CurrentRoleSettings permission={PagePermissionEnum.NTAK_BOXOFFICE_EVENTS}>
        <CustomizedModal
          open={openNewOfflineTicketModal}
          onHandleClose={handleCloseNewOfflineTicketModal}
          defaultFullscreen={isMobile}
          title={
            !isEditMode
              ? translate('ntak.boxoffice.offlineTicket.newOnsiteTicket')
              : translate('ntak.boxoffice.offlineTicket.editOnsiteTicket')
          }
          eventName={event.name}
        >
          <NewOfflineTicketForm
            handleClose={handleCloseNewOfflineTicketModal}
            isEditMode={isEditMode}
            event={event}
            onlineTickets={event.ticketTypes ?? []}
            onAddNewOfflineTicketType={handleAddNewOfflineTicketType}
            onUpdateOfflineTicketTypes={handleUpdateOfflineTicketType}
            editedOfflineTickets={editedOfflineTickets}
          />
        </CustomizedModal>
        <Container>
          <HeaderBreadcrumbs
            heading={translate('ntak.boxoffice.offlineTicket.title')}
            links={[
              { name: translate('ntak.boxoffice.breadcrumb.dashboard'), href: PATH_DASHBOARD.root },
              {
                name: translate('ntak.boxoffice.breadcrumb.boxOffice'),
                href: PATH_DASHBOARD.boxoffice.root,
              },
              { name: translate('ntak.boxoffice.breadcrumb.onSiteTicketing') },
            ]}
            action={
              <Button
                variant="contained"
                onClick={handleOpenNewOfflineTicketModal}
                startIcon={<IconifyIcon icon={IconifyIconEnum.Plus} />}
              >
                {translate('ntak.boxoffice.offlineTicket.newOnsiteTicket')}
              </Button>
            }
          />

          <OfflineTicketEditor
            offlineTickets={offlineTickets}
            event={event}
            setOfflineTickets={setOfflineTickets}
            setIsEditMode={setIsEditMode}
            setEditedOfflineTickets={setEditedOfflineTickets}
            setOpenNewOfflineTicketModal={setOpenNewOfflineTicketModal}
          />
        </Container>
      </CurrentRoleSettings>
    </Page>
  );
};

export default NtakBoxOfficeOfflineTicketEditor;
