import { useTranslation } from 'react-i18next';
import { allLangs, defaultLang } from '../config';
import i18n from '../locales/i18n';
import { TranslationKey } from 'src/@types/translation';
import { TOptions } from 'i18next';

export default function useLocales() {
  const { t } = useTranslation();

  const langStorage = localStorage.getItem('i18nextLng');

  const currentLang = allLangs.find((_lang) => _lang.value === langStorage) || defaultLang;

  const handleChangeLanguage = (newlang: string) => {
    i18n.changeLanguage(newlang);
  };

  function translate(
    key: TranslationKey,
    options?: TOptions,
    config?: { allowString: false }
  ): string;
  function translate(
    key: string,
    options: TOptions | undefined,
    config: { allowString: true }
  ): string;
  function translate(key: TranslationKey | string, options?: TOptions | undefined): string {
    return t(key, options);
  }

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLangs,
  };
}
