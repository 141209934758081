import { BoxProps, SxProps } from '@mui/material';

export interface IconProps extends BoxProps {
  sx?: SxProps;
  icon: IconifyIconEnum | IconifyMenuIconEnum;
}

export enum IconifyIconEnum {
  Alert = 'eva:alert-triangle-outline',
  AlertCircle = 'eva:alert-circle-outline',
  ArrowUp = 'eva:arrow-upward-outline',
  ArrowDown = 'eva:arrow-downward-outline',
  DoubleArrowUp = 'eva:arrowhead-up-outline',
  DoubleArrowDown = 'eva:arrowhead-down-outline',
  DoubleArrowLeft = 'eva:arrowhead-left-outline',
  DoubleArrowRight = 'eva:arrowhead-right-outline',
  DoubleArrowRightBold = 'ic:outline-double-arrow',
  DragIndicator = 'ic:outline-drag-indicator',
  Calendar = 'eva:calendar-outline',
  Cart = 'ic:outline-shopping-cart',
  CartEmpty = 'ic:outline-shopping-cart',
  CartAdd = 'ic:outline-add-shopping-cart',
  Cash = 'flowbite:cash-outline',
  Cashier = 'ph:cash-register',
  Checkmark = 'eva:checkmark-outline',
  CheckmarkCircle = 'eva:checkmark-circle-outline',
  ChevronDown = 'eva:chevron-down-outline',
  ChevronLeft = 'eva:chevron-left-outline',
  ChevronRight = 'eva:chevron-right-outline',
  ChevronUp = 'eva:chevron-up-outline',
  Close = 'eva:close-outline',
  CloseCircle = 'eva:close-circle-outline',
  Comment = 'eva:message-circle-outline',
  Copy = 'ic:outline-content-copy',
  Collapse = 'eva:collapse-outline',
  Expand = 'eva:expand-outline',
  ExternalLink = 'eva:external-link-outline',
  Delete = 'eva:trash-2-outline',
  Download = 'eva:arrow-circle-down-outline',
  DownloadFill = 'eva:arrow-circle-down-fill',
  Edit = 'eva:edit-outline',
  Eye = 'eva:eye-outline',
  EyeOff = 'eva:eye-off-outline',
  File = 'eva:file-text-outline',
  Filter = 'mdi:filter-variant',
  FocusCenter = 'mdi:image-filter-center-focus-weak',
  Help = 'eva:question-mark-circle-outline',
  Info = 'eva:info-outline',
  List = 'eva:list-outline',
  Lock = 'eva:lock-outline',
  Unlock = 'eva:unlock-outline',
  Menu = 'eva:menu-outline',
  MenuFill = 'eva:menu-2-fill',
  Moon = 'eva:moon-outline',
  More = 'eva:more-vertical-outline',
  Options = 'eva:options-2-outline',
  Unsubscribe = 'ic:outline-unsubscribe',
  People = 'eva:people-fill',
  Plus = 'eva:plus-outline',
  Refresh = 'eva:refresh-outline',
  Save = 'eva:save-outline',
  Search = 'ic:outline-search',
  Send = 'eva:paper-plane-outline',
  Submit = 'ic:baseline-send',
  Sun = 'eva:sun-outline',
  Settings = 'eva:settings-2-outline',
  Statistics = 'material-symbols:finance-mode-rounded',
  Ticket = 'ion:ticket-outline',
  Wallet = 'ic:outline-wallet',
  Warning = 'ic:outline-warning-amber',
  ZoomIn = 'eva:maximize-outline',
  ZoomOut = 'eva:minimize-outline',
}

export enum IconifyMenuIconEnum {
  CALENDAR = 'ic:outline-event-note',
  STATISTICS = 'material-symbols:finance-mode-rounded',
  MARKETING = 'streamline:annoncement-megaphone',
  NTAK = 'tdesign:city-4',
  SALE = 'ph:cash-register',
  TICKET = 'ion:ticket-outline',
  FINANCE = 'fluent:receipt-money-16-regular',
  PERSON = 'ic:outline-person',
  GROUP = 'ic:outline-group',
  OFFER = 'ic:outline-local-offer',
}
