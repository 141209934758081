import { useFormContext, Controller } from 'react-hook-form';
import { TextField, TextFieldProps } from '@mui/material';
import { useIMask } from 'react-imask';

type IProps = {
  name: string;
  mask: string;
  placeholderChar?: string;
  lazy?: boolean;
};

type Props = IProps & TextFieldProps;

export default function RHFAccountNumberField({
  name,
  mask,
  placeholderChar = '_',
  lazy = true,
  ...other
}: Props) {
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useFormContext();
  const { ref } = useIMask(
    {
      mask,
      lazy,
      placeholderChar,
    },
    {
      onAccept: (_, mask) => {
        const { unmaskedValue } = mask;
        const regex = /(.{8})(?=.)/g;
        const newStr = unmaskedValue.replace(regex, '$1-');

        const fieldError = errors[name];

        setValue(name, newStr, {
          shouldValidate:
            fieldError && (newStr.length === 1 || newStr.length === 17 || newStr.length === 26),
        });
      },
    }
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value }, fieldState: { error } }) => (
        <TextField
          inputRef={ref}
          fullWidth
          placeholder="00000000-00000000-00000000"
          defaultValue={getValues(name)}
          onBlur={() => setValue(name, value, { shouldValidate: true })}
          error={!!error}
          helperText={error?.message}
          slotProps={{
            htmlInput: {
              'data-testid': 'bank-account-number',
            },
          }}
          {...other}
        />
      )}
    />
  );
}
