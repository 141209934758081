import { UserInvitationData } from '../@types/user';
import { jwtDecode } from 'jwt-decode';
import { PATH_AUTH } from '../routes/paths';
import axios from './axios';

type RefreshTokenProps = {
  refreshToken: string;
  needsRefreshToken?: boolean;
  extendToken?: boolean;
};

let expiredAccessTokenTimer: number;

const isValidToken = (token: string) => {
  if (!token) {
    return false;
  }

  try {
    const decoded = jwtDecode<{ exp: number }>(token);

    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  } catch (error) {
    console.error(error);

    return false;
  }
};

const getUserIdFromAuthToken = (authToken: string) => {
  const userIdFromToken = jwtDecode<{ sub: string }>(authToken);

  return userIdFromToken.sub;
};

const getEmailFromToken = (token: string) => {
  const emailFromToken = jwtDecode<{ email: string }>(token);

  return emailFromToken.email;
};

const getUserInvitationDataFromToken = (token: string) => {
  const userInvitationData = jwtDecode<UserInvitationData>(token);

  return userInvitationData;
};

const handleTokenExpired = (exp: number) => {
  clearSetTimeout();

  const refreshToken = localStorage.getItem('refreshToken');

  if (refreshToken) {
    const currentTime = Date.now();
    const { exp: expRefreshToken } = jwtDecode<{ exp: number }>(refreshToken);

    const timeLeft =
      expRefreshToken >= exp
        ? exp * 1000 - currentTime - 10000
        : expRefreshToken * 1000 - currentTime + 100;

    expiredAccessTokenTimer = window.setTimeout(async () => {
      await handleRefreshToken({ refreshToken });
    }, timeLeft);
  } else {
    window.location.href = PATH_AUTH.login;
  }
};

const handleRefreshToken = async ({ refreshToken, needsRefreshToken }: RefreshTokenProps) => {
  axios.defaults.headers.common.Authorization = `Bearer ${refreshToken}`;

  const userId = getUserIdFromAuthToken(refreshToken);

  try {
    const response = await axios.post('user/refresh', { needsRefreshToken });

    const { accessToken, refreshToken } = response.data;

    !refreshToken ? setSession(accessToken) : setSession(accessToken, refreshToken);
  } catch (error) {
    setSession(null);

    try {
      await axios.post('user/logout', { userId, refreshToken });
    } catch (error) {
      console.error(error);
    }

    window.location.href = PATH_AUTH.login;
  }
};

const setSession = (accessToken: string | null, refreshToken: string | null = null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    refreshToken && localStorage.setItem('refreshToken', refreshToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

    const { exp } = jwtDecode<{ exp: number }>(accessToken);

    handleTokenExpired(exp);
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const clearSetTimeout = () => {
  window.clearTimeout(expiredAccessTokenTimer);
};

export {
  getEmailFromToken,
  getUserIdFromAuthToken,
  getUserInvitationDataFromToken,
  handleRefreshToken,
  isValidToken,
  setSession,
  clearSetTimeout,
};
