import { Typography, TypographyProps } from '@mui/material';
import { Currency } from 'src/@types/user';
import { useAppSelector } from 'src/redux/hook';

type Props = TypographyProps & {
  amount: number;
};

export default function AmountWithCurrency({
  amount,
  variant = 'inherit',
  color = 'inherit',
  ...other
}: Props) {
  const currentOrganizer = useAppSelector((state) => state.userOrganizers.currentUserOrganizer);

  const organizerCurrency = currentOrganizer?.currency
    ? (Currency[currentOrganizer.currency as keyof typeof Currency] ?? currentOrganizer.currency)
    : 'Ft';

  return (
    <Typography variant={variant} whiteSpace="nowrap" color={color} {...other}>
      {`${Intl.NumberFormat('hu-HU').format(amount)} ${organizerCurrency}`}
    </Typography>
  );
}
