import {
  Box,
  IconButton,
  Dialog,
  Slide,
  DialogContent,
  Typography,
  FormHelperText,
} from '@mui/material';
import { Stack } from '@mui/system';
import useResponsive from 'src/hooks/useResponsive';
import SimpleBar from 'simplebar-react';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { TransitionProps } from 'notistack';
import MultilingualName from './MultilingualName';
import useLocales from 'src/hooks/useLocales';
import { fDateTimeSuffixEn, fDateTimeSuffixHu } from 'src/utils/format-time';
import { toZonedTime } from 'date-fns-tz';
import { IconifyIconEnum } from 'src/@types/icon';
import IconifyIcon from './icons/IconifyIcon';

type Props = {
  open: boolean;
  testId?: string;
  defaultFullscreen?: boolean;
  children: ReactNode;
  dialogActions?: ReactNode;
  onHandleClose?: VoidFunction;
  title?: string;
  eventName?: string;
  eventNameEnglish?: string;
  eventStartDate?: string;
};

export type ScrollToTopHandle = {
  scrollToTop: VoidFunction;
};

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomizedModal = forwardRef<ScrollToTopHandle, Props>(
  (
    {
      open,
      testId,
      children,
      defaultFullscreen = false,
      dialogActions,
      onHandleClose,
      title,
      eventName,
      eventNameEnglish,
      eventStartDate,
    },
    ref
  ) => {
    const { currentLang } = useLocales();
    const smDown = useResponsive('down', 'sm');

    const [fullscreen, setFullscreen] = useState(defaultFullscreen);

    const dialogContentRef = useRef<HTMLDivElement>(null);

    const toggleFullscreen = () => {
      setFullscreen((prev) => !prev);
    };

    useImperativeHandle(ref, () => ({
      scrollToTop: () => {
        dialogContentRef.current?.scrollTo({ top: 0, behavior: 'smooth' });
      },
    }));

    useEffect(() => setFullscreen(defaultFullscreen), [defaultFullscreen]);

    return (
      <Dialog
        open={open}
        onClose={onHandleClose}
        data-testid={testId}
        fullWidth
        scroll="paper"
        fullScreen={fullscreen}
        TransitionComponent={defaultFullscreen ? Transition : undefined}
        sx={{ '& .MuiPaper-root': { maring: 0 } }}
      >
        <DialogContent sx={{ padding: 0 }} ref={dialogContentRef}>
          <Box>
            {onHandleClose && (
              <Stack width="100%" position="absolute">
                <IconButton
                  onClick={onHandleClose}
                  style={{
                    alignSelf: 'flex-end',
                    marginTop: 8,
                    marginRight: 16,
                    position: 'sticky',
                    zIndex: 999,
                    display: '',
                  }}
                >
                  <IconifyIcon icon={IconifyIconEnum.Close} />
                </IconButton>
                {!defaultFullscreen && smDown && (
                  <IconButton
                    onClick={toggleFullscreen}
                    style={{
                      alignSelf: 'flex-end',
                      marginTop: 8,
                      marginRight: 48,
                      position: 'absolute',
                      zIndex: 999,
                    }}
                  >
                    {fullscreen ? (
                      <IconifyIcon icon={IconifyIconEnum.Collapse} />
                    ) : (
                      <IconifyIcon icon={IconifyIconEnum.Expand} />
                    )}
                  </IconButton>
                )}
              </Stack>
            )}
            <SimpleBar autoHide={false} forceVisible style={{ maxHeight: 'inherit' }}>
              <Box p={3} sx={{ marginTop: title ? '24px !important' : '' }}>
                {title && (
                  <Typography variant="h5" mb={eventName || eventStartDate ? 0 : 3}>
                    {title}
                  </Typography>
                )}
                <Box mb={3}>
                  {eventName && (
                    <FormHelperText sx={{ margin: 0 }}>
                      <MultilingualName name={eventName} englishName={eventNameEnglish ?? ''} />
                    </FormHelperText>
                  )}
                  {eventStartDate && (
                    <FormHelperText sx={{ margin: 0 }}>
                      {currentLang.value === 'hu'
                        ? fDateTimeSuffixHu(toZonedTime(eventStartDate, 'UTC'))
                        : fDateTimeSuffixEn(toZonedTime(eventStartDate, 'UTC'))}
                    </FormHelperText>
                  )}
                </Box>

                {children}
              </Box>
            </SimpleBar>
          </Box>
        </DialogContent>

        {dialogActions}
      </Dialog>
    );
  }
);

export default CustomizedModal;
