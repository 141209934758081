import axios from 'axios';
import axiosRetry from 'axios-retry';
import { handleRefreshToken } from './jwt';
import { PATH_AUTH } from 'src/routes/paths';
import { appEnvVariables } from 'src/config';

const axiosInstance = axios.create({
  baseURL: appEnvVariables.api,
  timeout: 10000,
});

axiosRetry(axiosInstance, { retries: 3, retryDelay: axiosRetry.exponentialDelay });

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      originalRequest &&
      !originalRequest.url.endsWith('user/refresh') &&
      error.response &&
      error.response.status === 401 &&
      originalRequest['axios-retry'].retryCount === 0
    ) {
      originalRequest['axios-retry'].retryCount += 1;

      const refreshToken = localStorage.getItem('refreshToken');

      if (refreshToken) {
        await handleRefreshToken({ refreshToken: refreshToken });

        let accessToken = localStorage.getItem('accessToken');

        originalRequest.headers.Authorization = accessToken ? 'Bearer ' + accessToken : '';

        return axiosInstance(originalRequest);
      } else {
        window.location.href = PATH_AUTH.login;
      }
    }

    return Promise.reject((error.response && error.response.data) || error);
  }
);

export default axiosInstance;
