import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import useLocales from 'src/hooks/useLocales';

interface DeleteDialogProps {
  open: boolean;
  dataTestId: string;
  onClose: () => void;
  deleteDialogContent: React.ReactNode;
  isDeleteAllowed: boolean;
  handleDelete: () => void;
}

const DeleteDialog: React.FC<DeleteDialogProps> = ({
  open,
  dataTestId,
  onClose,
  deleteDialogContent,
  isDeleteAllowed,
  handleDelete,
}) => {
  const { translate } = useLocales();
  return (
    <Dialog open={open} data-testid={dataTestId} onClose={onClose}>
      <DialogTitle>{translate('eventNewEdit.ticket.deleteDialog.title')}</DialogTitle>
      <DialogContent>{deleteDialogContent}</DialogContent>
      <DialogActions sx={{ padding: '10px' }}>
        {isDeleteAllowed ? (
          <>
            <Button onClick={onClose} variant="outlined" autoFocus>
              {translate('eventNewEdit.ticket.deleteDialog.back')}
            </Button>
            <Button
              data-testid={'confirm'}
              color="error"
              onClick={handleDelete}
              variant="contained"
              autoFocus
            >
              {translate('eventNewEdit.ticket.deleteDialog.confirm')}
            </Button>
          </>
        ) : (
          <Button onClick={onClose} variant="outlined" autoFocus>
            {translate('eventNewEdit.ticket.deleteDialog.ok')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
